import { Round } from "./Formatter";

const ResizebleMethodsSplit = async (ResizebleObj, spliter) => {
    if (ResizebleObj.init) return;
    ResizebleObj.init = true;
    ResizebleObj.firstClientX = 0;
    ResizebleObj.deylerEnd = true;
    ResizebleObj.tableCols = spliter.cols;

    if (spliter.storageKey) {
        ResizebleObj.storageKey = spliter.storageKey;
        ResizebleObj.tableCols = JSON.parse(localStorage.getItem(ResizebleObj.storageKey)) || spliter.cols;
    }

    ResizebleObj.GetStyle = () => ResizebleObj.tableCols.map((_col) => `${_col}fr`).join(" ");
    ResizebleObj.style = ResizebleObj.GetStyle();

    const onDragEnd = () => {
        ResizebleObj.storageKey && localStorage.setItem(ResizebleObj.storageKey, JSON.stringify(ResizebleObj.tableCols));
    };

    ResizebleObj.style = `${ResizebleObj.tableCols[0]}fr ${ResizebleObj.tableCols[1]}fr`;

    ResizebleObj.resizeTregered = (clientX, _colIndex, touch) => {
        ResizebleObj.firstClientX = clientX;
        ResizebleObj.targetOldWidth = ResizebleObj.tableCols[0];
        ResizebleObj.relatedIOldWidth = ResizebleObj.tableCols[1];
        ResizebleObj.tableWidth = ResizebleObj.tableRef.current.clientWidth; // - (ResizebleObj.showIndex ? 30 : 0);

        if (touch) TouchMoveEventHandler(resizeEventHandler, onDragEnd);
        else MouseMoveEventHandler(resizeEventHandler, onDragEnd);
    };
    const resizeEventHandler = ({ clientX }) => {
        if (ResizebleObj.deylerEnd) {
            Deyler(ResizebleObj);
            ResizebleObj.result = Round((ResizebleObj.firstClientX - clientX) / ResizebleObj.tableWidth);
            UpdateColsWidth(ResizebleObj);
        }
    };
};

export default ResizebleMethodsSplit;

function Deyler(ResizebleObj) {
    ResizebleObj.deylerEnd = false;
    setTimeout(() => {
        ResizebleObj.deylerEnd = true;
    }, 2);
}

function MouseMoveEventHandler(resizeEventHandler, onDragEnd) {
    const removeEvents = () => {
        onDragEnd();
        window.removeEventListener("mouseup", removeEvents);
        window.removeEventListener("mousemove", resizeEventHandler);
    };
    window.addEventListener("mouseup", removeEvents);
    window.addEventListener("mousemove", resizeEventHandler);
}

function TouchMoveEventHandler(resizeEventHandler, onDragEnd) {
    const touchResize = ({ touches }) => resizeEventHandler({ clientX: touches[0].clientX });

    const removeEvents = () => {
        onDragEnd();
        window.removeEventListener("touchend", removeEvents);
        window.removeEventListener("touchmove", touchResize);
    };
    window.addEventListener("touchend", removeEvents);
    window.addEventListener("touchmove", touchResize);
}

function UpdateColsWidth(ResizebleObj) {
    let targetNewWidth = Round(ResizebleObj.targetOldWidth + ResizebleObj.result);
    if (targetNewWidth < 0.2) return;
    let relatedNewWidth = Round(ResizebleObj.relatedIOldWidth - ResizebleObj.result);
    if (relatedNewWidth < 0.2) return;

    ResizebleObj.tableCols[0] = targetNewWidth;
    ResizebleObj.tableCols[1] = relatedNewWidth;

    ResizebleObj.tableRef.current.style.gridTemplateColumns = `${ResizebleObj.tableCols[0]}fr ${ResizebleObj.tableCols[1]}fr`;
}
