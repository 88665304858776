import "./searchOptions.css";
import React from "react";
import DefaultNoData from "../Defaults/DefaultNoData";
import DefaultItemBuilder from "../Defaults/DefaultItemBuilder";
import DefaultErrorBuilder from "../Defaults/DefaultErrorBuilder";
import DefaultLoader from "../Defaults/DefaultLoader";
import SearchOptionsGridNodeContainer from "./SearchOptionsGridNodeContainer";
import SearchableOptions from "./SearchableOptions";
import OwlSearchOptionsMethods from "./OwlSearchOptionsMethods";
import PageStateProvider from "./PageStateKit/PageStateProvider";
const Owl = {};

const prop = {
    key: "query",
    type: "String",
    title: "اسم الزبون",
    value: "زبون نقدي",
    showInClearBar: false,
    queryChanged,
    defaultValue: { name: "زبون نقدي", id: 999 },
};

function queryChanged(child) {
    Owl.queryParams[child.key] = child;
    Owl.search();
}

const OwlSearchOptionsContainer = ({
    url,
    api,
    queries = null,
    onResult = null,
    onError = null,
    loader = DefaultLoader,
    noData = DefaultNoData,
    errorBuilder = DefaultErrorBuilder,
    header,
    itemBuilder = DefaultItemBuilder,
    queryContainerClass = "owl-search-query-conainer",
    useCash,
    firstLaunch,
    searchOptionsChange = () => {},
    displayKey = "name",
    className = "owl-search-scroller scroller",
}) => {
    console.debug("Owl Simple Container RDS");

    React.useMemo(() => {
        console.debug("Effect Owl Simple Container RD");
        OwlSearchOptionsMethods.getOwl(Owl, { url, api, queries, onResult, onError, useCash, firstLaunch });
    }, []);

    function _searchOptionsChange(value) {
        Owl.searchRef.current.value = value.name;
        Owl.setShowItems(false);
        searchOptionsChange(value);
    }

    const showItems = () => {
        if (!Owl.showItems) {
            Owl.setShowItems(true);
        }
    };

    return (
        <div onClick={showItems} className="owl-search-container">
            <SearchableOptions prop={prop} Owl={Owl} />
            <SearchOptionsGridNodeContainer
                Owl={Owl}
                ItemBuilder={itemBuilder}
                searchOptionsChange={_searchOptionsChange}
                defaultValue={prop.defaultValue}
                className={className}
            />
            <PageStateProvider Owl={Owl} />
        </div>
    );
};
export default OwlSearchOptionsContainer;
