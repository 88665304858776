import React from "react";
import Table from "../Table/Table";

const TableOrGirdContainer = ({ Owl, ItemBuilder, gridClass }) => {
    console.debug("ItemsContainer RD");
    [Owl.items, Owl.setItems] = React.useState([]);
    [Owl.spliter.table, Owl.spliter.setTable] = React.useState();

    return Owl.spliter.table ? (
        Owl.items.length > 0 && <Table spliter={Owl.spliter} items={Owl.items} ResizebleObj={Owl.ResizebleObj} ItemBuilder={ItemBuilder} />
    ) : (
        <div className={gridClass}>
            {Owl.items.map((item, i) => (
                <ItemBuilder key={i} item={item} Owl={Owl} />
            ))}
        </div>
    );
};

export default TableOrGirdContainer;
