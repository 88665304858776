import React from "react";

const Button = ({ title = "Click Me!", extra = "", onClick }) => {
    return (
        <p className={`this-button ${extra}`} onClick={onClick}>
            {title}
        </p>
    );
};

export default Button;
