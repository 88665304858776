import React from "react";
import OwlScroller from "./OwlScroller";
import DefaultNoData from "../Defaults/DefaultNoData";
import DefaultItemBuilder from "../Defaults/DefaultItemBuilder";
import DefaultErrorBuilder from "../Defaults/DefaultErrorBuilder";
import DefaultLoader from "../Defaults/DefaultLoader";
import OwlMethods from "../Utils/OwlMethods";
import TableOrGirdContainer from "./TableOrGirdContainer";
import GridNodeContainer from "./GridNodeContainer";
import OwlItems from "./OwlItems";
import SplitContainer from "./SplitContainer ";
import SplitDetailsContainer from "./SplitDetailsContainer";

const splitCols = [0.6, 0.4];

const tableCols = [
    { key: "id", title: "الرقم", initialWidthFactor: 0.6 },
    { key: "name", title: "السعر", initialWidthFactor: 0.2 },
    { key: "quantity", title: "العدد", initialWidthFactor: 0.2 },
];

const _spliter = {
    cols: splitCols,
    containerClass: "split-container",
    storageKey: "split-colums",
    // isTable: true,
    // widthToDisplay: 1000,
    // className: "owl-table",
    // showIndex: true,
    // minColWidth: 0.1,
};

const Owl = {};

const OwlContainer = ({
    url,
    api,
    queries = null,
    onResult = null,
    onError = null,
    loader = DefaultLoader,
    noData = DefaultNoData,
    errorBuilder = DefaultErrorBuilder,
    header,
    itemBuilder = DefaultItemBuilder,
    gridClass = "owl-grid",
    queryContainerClass = "owl-query-conainer",
    detailsClass = "details-container",
    detailsBuilder,
    useCash,
    firstLaunch,
    spliter,
    poupKeyboard,
}) => {
    console.debug("OwlContainer RDS");

    React.useMemo(() => {
        console.debug("Effect Owl Container RD");
        OwlMethods.getOwl(Owl, { url, api, queries, onResult, onError, useCash, spliter, firstLaunch, poupKeyboard });
    }, []);

    const queryChanged = (child) => {
        if (child.clear)
            Owl.setQueryParams((_queryParams) => {
                _queryParams[child.key] = child;
                return { ..._queryParams };
            });
        else Owl.queryParams[child.key] = child;
        Owl.search();
    };

    if (spliter) {
        return spliter.isTable ? (
            <OwlScroller Owl={Owl}>
                <OwlItems queries={queries} queryContainerClass={queryContainerClass} Owl={Owl} queryChanged={queryChanged} header={header} />
                <TableOrGirdContainer Owl={Owl} ItemBuilder={itemBuilder} className={gridClass} />
            </OwlScroller>
        ) : (
            <SplitContainer className={spliter.className} Owl={Owl} ResizebleObj={Owl.ResizebleObj}>
                <div className="z-10">
                    <OwlItems queries={queries} queryContainerClass={queryContainerClass} Owl={Owl} queryChanged={queryChanged} header={header} />
                </div>
                <OwlScroller Owl={Owl}>
                    <GridNodeContainer Owl={Owl} ItemBuilder={itemBuilder} className={gridClass} />
                </OwlScroller>
                <SplitDetailsContainer Owl={Owl} ResizebleObj={Owl.ResizebleObj} className={detailsClass} DetailsBuilder={detailsBuilder} />
            </SplitContainer>
        );
    } else
        return (
            <OwlScroller Owl={Owl}>
                <GridNodeContainer Owl={Owl} ItemBuilder={itemBuilder} className={gridClass} />
            </OwlScroller>
        );
};

export default OwlContainer;
