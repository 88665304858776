import React from "react";
const String = ({ prop }) => {
    console.debug("String RD");

    // return (
    //     <div className="owl-label-conatiner">
    //         <input type="text" className="owl-date" defaultValue={prop.value} onChange={onChange} />
    //         <p className="owl-label">{prop.title} </p>
    //     </div>
    // );
    const ref = React.useRef();
    return (
        <div ref={ref} key={prop.key} className={prop.containerClass}>
            <input
                type="text"
                name={prop.key}
                placeholder={prop.title}
                onChange={({ target }) => {
                    checkChange(target, prop, prop.queryChanged);
                    if (ref.current.childNodes[1].style.display === "none") ref.current.childNodes[1].style.display = "block";
                }}
                className={`owl-input ${prop.className}`}
            />
            <span
                className="clear-icon"
                style={{ display: prop.value ? "block" : "none" }}
                onClick={(e) => {
                    prop.value = "";
                    ref.current.childNodes[0].value = "";
                    e.target.style.display = "none";
                    prop.queryChanged();
                }}>
                x
            </span>
        </div>
    );
};

export default String;

const checkChange = (target, prop, onChange) => {
    let pastValue = target.value;
    setTimeout(() => {
        if (pastValue !== target.value) return;
        {
            prop.value = target.value;
            if (prop.showInClearBar !== false)
                prop.clear = () => {
                    target.value = "";
                };
            onChange();
        }
    }, 700);
};
