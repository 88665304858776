import { Round } from "./Formatter";

const getResizebleTableObj = async (ResizebleObj, spliter) => {
    if (ResizebleObj.init) return;
    ResizebleObj.init = true;
    ResizebleObj.colIndex = 0;
    ResizebleObj.firstClientX = 0;
    ResizebleObj.deylerEnd = true;
    ResizebleObj.minColWidth = spliter.minColWidth || 0.1;
    ResizebleObj.tableCols = spliter.cols.map((col) => col.initialWidthFactor);

    if (spliter.storageKey) {
        ResizebleObj.storageKey = spliter.storageKey;
        let storage = JSON.parse(localStorage.getItem(ResizebleObj.storageKey));
        if (storage) ResizebleObj.tableCols = storage;
    }

    if (spliter.showIndex) ResizebleObj.GetStyle = () => `30px ${ResizebleObj.tableCols.map((_col) => `${_col}fr`).join(" ")}`;
    else ResizebleObj.GetStyle = () => ResizebleObj.tableCols.map((_col) => `${_col}fr`).join(" ");
    ResizebleObj.style = ResizebleObj.GetStyle();

    const onDragEnd = () => {
        ResizebleObj.storageKey && localStorage.setItem(ResizebleObj.storageKey, JSON.stringify(ResizebleObj.tableCols));
    };

    ResizebleObj.resizeTregered = (clientX, _colIndex, touch) => {
        ResizebleObj.firstClientX = clientX;
        ResizebleObj.colIndex = _colIndex;
        ResizebleObj.targetOldWidth = ResizebleObj.tableCols[ResizebleObj.colIndex];
        ResizebleObj.relatedIndex = _colIndex + 1;
        ResizebleObj.relatedIOldWidth = ResizebleObj.tableCols[ResizebleObj.relatedIndex];
        ResizebleObj.tableWidth = ResizebleObj.tableRef.current.clientWidth; // - (ResizebleObj.showIndex ? 30 : 0);

        if (touch) TouchMoveEventHandler(resizeEventHandler, onDragEnd);
        else MouseMoveEventHandler(resizeEventHandler, onDragEnd);
    };
    const resizeEventHandler = ({ clientX }) => {
        if (ResizebleObj.deylerEnd) {
            Deyler(ResizebleObj);
            ResizebleObj.result = Round((ResizebleObj.firstClientX - clientX) / ResizebleObj.tableWidth);
            UpdateColsWidth(ResizebleObj);
        }
    };
    if (spliter.isTable) {
        ResizebleObj.SetStyle = () => {
            ResizebleObj.style = ResizebleObj.GetStyle();
            ResizebleObj.tableRef.current.style.gridTemplateColumns = ResizebleObj.style;
            ResizebleObj.tableRef.current.firstChild.style.gridTemplateColumns = ResizebleObj.style;
        };
        TriggerTableOrGridEvent(spliter);
    } else {
        ResizebleObj.SetStyle = () => {
            ResizebleObj.style = ResizebleObj.GetStyle();
            ResizebleObj.tableRef.current.style.gridTemplateColumns = ResizebleObj.style;
        };
    }
};

export default getResizebleTableObj;

function Deyler(ResizebleObj) {
    ResizebleObj.deylerEnd = false;
    setTimeout(() => {
        ResizebleObj.deylerEnd = true;
    }, 3);
}

function MouseMoveEventHandler(resizeEventHandler, onDragEnd) {
    const removeEvents = () => {
        onDragEnd();
        window.removeEventListener("mouseup", removeEvents);
        window.removeEventListener("mousemove", resizeEventHandler);
    };
    window.addEventListener("mouseup", removeEvents);
    window.addEventListener("mousemove", resizeEventHandler);
}

function TouchMoveEventHandler(resizeEventHandler, onDragEnd) {
    const touchResize = ({ touches }) => resizeEventHandler({ clientX: touches[0].clientX });

    const removeEvents = () => {
        onDragEnd();
        window.removeEventListener("touchend", removeEvents);
        window.removeEventListener("touchmove", touchResize);
    };
    window.addEventListener("touchend", removeEvents);
    window.addEventListener("touchmove", touchResize);
}

function TriggerTableOrGridEvent(spliter) {
    if (spliter.widthToDisplay) {
        setTimeout(() => {
            spliter.setTable(window.innerWidth > spliter.widthToDisplay);
        }, 10);
        window.addEventListener("resize", (e) => {
            if (spliter.table) {
                if (e.target.innerWidth < spliter.widthToDisplay) {
                    spliter.table = false;
                    spliter.setTable(false);
                }
            } else if (e.target.innerWidth > spliter.widthToDisplay) {
                spliter.table = true;
                spliter.setTable(true);
            }
        });
    }
}

function UpdateColsWidth(ResizebleObj) {
    let targetNewWidth = Round(ResizebleObj.targetOldWidth + ResizebleObj.result);
    if (targetNewWidth < ResizebleObj.minColWidth) return;
    let relatedNewWidth = Round(ResizebleObj.relatedIOldWidth - ResizebleObj.result);
    if (relatedNewWidth < ResizebleObj.minColWidth) return;

    ResizebleObj.tableCols[ResizebleObj.colIndex] = targetNewWidth;
    ResizebleObj.tableCols[ResizebleObj.relatedIndex] = relatedNewWidth;

    ResizebleObj.SetStyle();
}
