let EnglishKeysNode = null;
let NumberKeysNode = null;
let ArabicKeysNode = null;

export const SetKeyboardTypeMethod = {
    text: function (keyboardProps, KyeboardNode) {
        GetkeyboradLang[keyboardProps.lang](keyboardProps, KyeboardNode);
    },
    number: function (keyboardProps, KyeboardNode) {
        if (!NumberKeysNode) NumberKeysNode = appendButtons(NumberKeysMap);
        keyboardProps.width = 210;
        keyboardProps.height = 250;
        KyeboardNode.className = "owl-popup-keybord-number";
        KyeboardNode.replaceChildren(...NumberKeysNode);
    },
};

const NumberKeysMap = [1, 2, 3, 4, 5, 6, 7, 8, 9, "⌦", 0, ".", "↩", "Clear", "ABC"];
const ArabicKeysMap = [
    "⌦",
    "د",
    "ج",
    "ح",
    "خ",
    "ه",
    "ع",
    "غ",
    "ف",
    "ق",
    "ث",
    "ص",
    "ض",

    "↩",
    "ط",
    "ك",
    "م",
    "ن",
    "ت",
    "ا",
    "ل",
    "ب",
    "ي",
    "س",
    "ش",

    "Num",
    "ظ",
    "ز",
    "و",
    "ة",
    "ى",
    "لا",
    "ر",
    "ؤ",
    "ء",
    "ئ",
    "ذ",
    "🌐",
];
const EnglishKeysMap = [
    "⌫",
    "]",
    "[",
    "p",
    "o",
    "i",
    "u",
    "y",
    "t",
    "r",
    "e",
    "w",
    "q",

    "↩",
    "'",
    ";",
    "l",
    "k",
    "j",
    "h",
    "g",
    "f",
    "d",
    "s",
    "a",

    "Num",
    "Caps",
    "?",
    ".",
    ",",
    "m",
    "n",
    "b",
    "v",
    "c",
    "x",
    "z",
    "🌐",
];

const GetkeyboradLang = {
    en: function (keyboardProps, KyeboardNode) {
        if (!EnglishKeysNode) EnglishKeysNode = appendButtons(EnglishKeysMap);
        KyeboardNode.className = "owl-popup-keybord";
        keyboardProps.width = 730;
        keyboardProps.height = 250;
        KyeboardNode.replaceChildren(...EnglishKeysNode);
    },
    ar: function (keyboardProps, KyeboardNode) {
        if (!ArabicKeysNode) ArabicKeysNode = appendButtons(ArabicKeysMap);
        KyeboardNode.className = "owl-popup-keybord";
        keyboardProps.width = 730;
        keyboardProps.height = 250;
        KyeboardNode.replaceChildren(...ArabicKeysNode);
    },
};

function appendButtons(keys) {
    return keys.map((button) => {
        return getButtonNode(button);
    });
}

const getButtonNode = (text) => {
    const button = document.createElement("p");
    button.className = "owl-popup-button";
    button.innerText = text;
    return button;
};

export function handleClosePoup(inputRef, KyeboardNode) {
    function handleMouseDown(e) {
        if (e.target !== inputRef && e.target.id !== "popupKeybord" && e.target.parentElement.id !== "popupKeybord") {
            KyeboardNode.remove();
            document.removeEventListener("mousedown", handleMouseDown);
            document.removeEventListener("touchstart", handleMouseDown);
        } else {
            e.preventDefault();
            return false;
        }
    }
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("touchstart", handleMouseDown);
}
