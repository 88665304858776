import CreateBillItemNode, { updateNodeChildren } from "./CreateBillItemNode";

export const AddItemDetailsEvent = (Owl) => {
    if (Owl.addOrUpdateItemDetails) return;
    Owl.billItems = { total: 0 };
    Owl.addOrUpdateItemDetails = (item) => {
        let _item = Owl.billItems[item.id];
        if (_item) updateExistingItem(Owl, item);
        else AddNewBillItem(item, Owl);
        Owl.billItemChanged(item, "UpdateTotal");
    };
};

function AddNewBillItem(item, Owl) {
    item.index = Owl.itemsDetailsRef.current.childNodes.length;
    Owl.billItems[item.id] = item;
    CreateBillItemNode(item, Owl);
}

export function AddNewBillsItem(Owl, clear) {
    if (clear) ClearBillItems(Owl);
    let billFinancial1 = Owl.billItems.billFinancial1;
    delete Owl.billItems.billFinancial1;
    Object.values(Owl.billItems).forEach((item) => {
        CreateBillItemNode(item, Owl);
    });
    Owl.billItems.billFinancial1 = billFinancial1 || { total: 0 };
}

function updateExistingItem(Owl, item) {
    Owl.billItems[item.id] = item;
    let childNode = Owl.itemsDetailsRef.current.childNodes[item.index];
    updateNodeChildren(childNode, item);
}

const ClearBillItems = (Owl) => {
    const parent = Owl.itemsDetailsRef.current;
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
    }
};

const ItemDetailsNode = () => {};
export default ItemDetailsNode;
