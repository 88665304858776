import "./index.css";
import Root from "./Test/Root";
import ReactDOM from "react-dom/client";
import React from "react";
import "./Sale/sale.css";
// import "./Reps/reps.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Root />
    </React.StrictMode>
);
