const mainClass = "item-builder owl-count-holder text-center relative";

const ItemNode = ({ item, Owl }) => {
    const container = createItemContainer(item, Owl);

    container.appendChild(createNameP(item));
    container.appendChild(createBillQuantitySpan(item));
    return container;
};

export default ItemNode;

function createItemContainer(item, Owl) {
    const onclick = (e) => {
        let child = e.currentTarget.children[1];
        if (!item.billQuantity) {
            item.billQuantity = 1;
            container.classList.add("owl-focus");
        } else item.billQuantity++;
        child.innerText = item.billQuantity;
        // e.currentTarget.id = "owl-warn";
        // e.currentTarget.id = "owl-acive";
        // e.currentTarget.id = "owl-ok";
        // e.currentTarget.id = "owl-error";
        Owl.addOrUpdateItemDetails(item);
    };

    const container = document.createElement("div");
    container.className = item.billQuantity ? `${mainClass} owl-focus` : mainClass;
    container.id = item.id;
    container.onclick = onclick;
    return container;
}

function createNameP(item) {
    const name = document.createElement("p");
    name.innerText = item.name;
    return name;
}

function createBillQuantitySpan(item) {
    const billQuantity = document.createElement("span");
    billQuantity.className = "owl-count state-target";
    billQuantity.innerText = item.billQuantity || "";
    return billQuantity;
}

export const UpdateItemsNodes = (Owl) => {
    const childNodes = Owl.itemsRef.current.childNodes;
    Object.keys(Owl.items).forEach((id) => {
        const item = Owl.items[id];
        let billItem = Owl.billItems[id];
        if (billItem) {
            let child = childNodes[item.childIndex];
            item.billQuantity = billItem.billQuantity;
            child.classList.add("owl-focus");
            child.children[1].innerText = billItem.billQuantity;
        } else if (item.billQuantity) {
            let child = childNodes[item.childIndex];
            child.className = mainClass;
            item.billQuantity = "";
            child.children[1].innerText = "";
        }
    });
};

export const AddItemEvent = (Owl) => {
    Owl.updateItem = (item) => {
        let _item = Owl.items[item.id];
        if (_item) {
            let child = Owl.itemsRef.current.childNodes[_item.childIndex];
            child.children[1].innerText = item.billQuantity;
            if (!item.billQuantity) child.className = mainClass;
        }
    };
};
