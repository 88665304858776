import React from "react";

const SplitDetailsContainer = ({ Owl, className, DetailsBuilder }) => {
    [Owl.details, Owl.setDetails] = React.useState({});
    return (
        <div className={className}>
            <Separative resizeTregered={Owl.ResizebleObj.resizeTregered} />
            <DetailsBuilder set={Owl.set} details={Owl.details} setDetails={Owl.setDetails} Owl={Owl} addEvent={Owl.addEvent} />
        </div>
    );
};

export default SplitDetailsContainer;

const Separative = ({ i = 0, resizeTregered }) => (
    <p
        onTouchStart={({ touches }) => {
            resizeTregered(touches[0].clientX, i, true);
        }}
        onMouseDown={({ clientX }) => {
            resizeTregered(clientX, i);
        }}
        id="resizeble-target-split"
        style={{ position: "absolute", padding: 1, height: "25%", zIndex: "1000", cursor: "col-resize", marginBlock: "auto" }}
        // className="table-line-split"
    />
);
