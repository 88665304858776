const Language = {
    PrintAndSave: "طباعة وحفظ",
    AddDiscount: "إضافة خصم",
    ReturnSales: "إرجاع",
    Total: "المجموع",
    Note: "اضافة ملاحظة",
    CencelTheList: "إلغاء القائمة",
    AllTheStores: "كل المتاجر",
    All: "الكل",
    SearchingFor: "...البحث عن",
    Settings: "الإعدادات",
    CencelTheDiscont: "إلغاء الخصم",
    CencelTheNote: "إلغاء الملاحظة",
    Total: "المجموع",
};

export default Language;
