import { API } from "../Sale/SalePointAPI";
import ItemsSyncMethods from "../Sale/Utils/ItemsSyncMethods";

const FirstLaunch = (Owl) => {
    getGropus();
    getStorse();
    setTimeout(() => {
        ItemsSyncMethods.SetupStorageMethods(Owl);
        ItemsSyncMethods.AddItemDetailsEvent(Owl);
        ItemsSyncMethods.AddItemEvent(Owl);
        let billsIds = JSON.parse(localStorage.getItem("billsIds"));
        billsIds.billId && Owl.switchBills(billsIds.billId, false);
    }, 10);
};

const getGropus = async () => {
    let groupChildren = [{ key: "groupId", title: "الكل", id: -1 }];
    const { data } = await API.get("groups?limit=100");
    data.forEach((element) => {
        groupChildren.push({ title: element.name, id: element.id });
    });
    const toLocalStorage = { key: "groupId", type: "ButtonsOptions", value: -1, options: groupChildren };
    localStorage.setItem("groups-list", JSON.stringify(toLocalStorage));
};

const getStorse = async () => {
    let storesChildren = [{ key: "stoteId", title: "كل المتاجر", id: -1 }];
    const { data } = await API.get("stores?limit=100");
    data.forEach((element) => {
        storesChildren.push({ title: element.name, id: element.id });
    });
    const toLocalStorage = { key: "storeId", type: "Options", value: -1, options: storesChildren };
    localStorage.setItem("inventory-list", JSON.stringify(toLocalStorage));
};

export default FirstLaunch;
