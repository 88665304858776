import React from "react";
import DefaultItemBuilder from "../Defaults/DefaultItemBuilder";

const Error = ({ owl }) => {
    return (
        <div
            onClick={({ target }) => {
                owl.fetch();
            }}
            className="fixed p-10 inset-0 z-50 row justify-center blur-bg overflow-auto">
            <div className="col-center col-span-full" style={{ maxWidth: 720 }}>
                <p className="text-white text-center animate-bounce rounded-full font-bold bg-red-500" style={{ padding: "40px 30px", opacity: 0.3 }}>
                    Error!
                </p>
                <DefaultItemBuilder item={owl.pageState[1]} />
            </div>
        </div>
    );
};

export default Error;
