import React, { Fragment } from "react";

const Table = ({ spliter, items, ResizebleObj }) => {
    console.debug("%cGridTable", "color: #0f0");
    ResizebleObj.tableRef = React.useRef(null);

    return (
        <div ref={ResizebleObj.tableRef} className={spliter.className + " round-table"} style={{ gridTemplateColumns: ResizebleObj.style }}>
            {spliter.showIndex ? (
                <Fragment>
                    <div className="table-header" style={{ gridTemplateColumns: ResizebleObj.style }}>
                        <IndexTitleTd />
                        {spliter.cols.map((col, i) => (
                            <TitleTd key={i} resizeTregered={ResizebleObj.resizeTregered} lastOne={spliter.cols.length - 1 === i} col={col.title} i={i} />
                        ))}
                    </div>
                    {items.map((item, i) =>
                        i % 2 == 0 ? (
                            <Fragment key={i}>
                                <OddIindexTd title={i} />
                                {spliter.cols.map(({ key }) => (
                                    <OddTd key={key} title={item[key]} />
                                ))}
                            </Fragment>
                        ) : (
                            <Fragment key={i}>
                                <EvenIindexTd title={i} />
                                {spliter.cols.map(({ key }) => (
                                    <EvenTd key={key} title={item[key]} />
                                ))}
                            </Fragment>
                        )
                    )}
                </Fragment>
            ) : (
                <Fragment>
                    <div className="table-header" style={{ gridTemplateColumns: ResizebleObj.style }}>
                        {spliter.cols.map((col, i) => (
                            <TitleTd key={i} resizeTregered={ResizebleObj.resizeTregered} lastOne={spliter.cols.length - 1 === i} col={col.title} i={i} />
                        ))}
                    </div>
                    {items.map((item, i) =>
                        i % 2 == 0 ? (
                            <Fragment key={i}>
                                {spliter.cols.map(({ key }) => (
                                    <OddTd key={key} title={item[key]} />
                                ))}
                            </Fragment>
                        ) : (
                            <Fragment key={i}>
                                {spliter.cols.map(({ key }) => (
                                    <EvenTd key={key} title={item[key]} />
                                ))}
                            </Fragment>
                        )
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default Table;
const OddIindexTd = ({ title }) => <span className="owl-table-td text-center owl-content">{title}</span>;
const EvenIindexTd = ({ title }) => <span className="owl-table-td text-center owl-content even">{title}</span>;
const OddTd = ({ title }) => <span className="owl-table-td owl-content">{title}</span>;
const EvenTd = ({ title }) => <span className="owl-table-td owl-content even">{title}</span>;

const TitleTd = ({ col, lastOne, i, resizeTregered }) =>
    !lastOne ? (
        <div
            className="col relative"
            onMouseDown={(e) => {
                if (e.target.id === "resizeble-target") resizeTregered(e.clientX, i);
            }}>
            {i === 0 && <p className="table-line cursor-default" />}
            <p className="cursor-default p-1 text-center">{col}</p>
            <p id="resizeble-target" className="table-line" />
        </div>
    ) : (
        <div className="col relative">
            <p className="text-center p-1">{col}</p>
            <p className="table-line left-0" />
        </div>
    );

const IndexTitleTd = ({ col = "ت" }) => (
    <div className="col relative">
        <p className="table-line cursor-default" />
        {/* <p className="table-line cursor-default left-0" /> */}
        <p className="cursor-default text-center p-1">{col}</p>
    </div>
);

// const displayRef = React.useRef(null);
// <p ref={displayRef} className="font-bold fixed  z-30" style={{ left: 10, top: 150, fontSize: 23 }}></p>
