import React from "react";
import QueryCleaner from "./QueryCleaner";
import QueryGroupContainer from "./QueryGroupBuilder";

const QueryContainer = ({ Owl, queries: children, queryChanged, className }) => {
    console.debug("QueryContainer RD");
    return (
        <div className={className}>
            <QueryGroupContainer prop={{ children, queryChanged }} />
            <QueryCleaner Owl={Owl} />
        </div>
    );
};

export default QueryContainer;
