import React from "react";
import BillDetailsContainer from "./Components/BillDetailsContainer";
import OwlContainer from "../Containers/OwlContainer";
import FirstLaunch from "../Utils/FirstLaunch";
import Consts from "./Utils/Consts";
import { API } from "./SalePointAPI";
import ItemNode from "./Items/ItemNode";
const listned = false;
const SalePointView = () => {
    React.useEffect(() => {
        setTimeout(() => {
            if (listned) return;
            // console.clear();
            // localStorage.clear();
        }, 100);
    }, []);
    return (
        <OwlContainer
            api={API}
            url={`items/touch`}
            firstLaunch={FirstLaunch}
            itemBuilder={ItemNode}
            detailsBuilder={BillDetailsContainer}
            queries={Consts.queries}
            useCash={true}
            spliter={Consts.spliter}
            poupKeyboard={true}
        />
    );
};
export default SalePointView;
