import React from "react";
import "../css/owl.css";
import "../css/others.css";
import "../css/owlContainers.css";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import Result from "./Result";
import SetupView from "./SetupView";
import DynamicView from "../DynamicView";
import SalePointView from "../Sale/SalePointView";
import UnAuthedRoutes from "../Sale/Routs/UnAuthedRoutes";

let init = false;

const Root = ({ children }) => {
    console.debug("Root RD");

    const Bodies = React.useMemo(() => {
        let APIs = JSON.parse(localStorage.getItem("APIs")) || false;
        const _bodies = [];
        const _routes = [];
        if (!APIs) return [];

        Object.values(APIs).forEach(({ routeName, title, endpoint, baseURL, token, appPackage, other }, index) => {
            // console.debug({ title, endpoint, baseURL, token, appPackage, other });
            _bodies.push(
                <Link className="link bg-white px-3 p-1 font-bold mx-1 rounded shadow-1" to={`/dynamic/${routeName}`}>
                    {routeName}
                </Link>
            );
            // _routes.push(<Route key={index} path={`/free/${routeName}-${endpoint}`} element={<DynamicView />} />);
        });

        localStorage.setItem("APIs", JSON.stringify(APIs));
        return _bodies;
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<SalePointView />} />
            </Routes>
            {/* <UnAuthedRoutes /> */}
            {/* <Route path="/" element={<SetupView />} />
                <Route path="/result" element={<Result />} />
            <Route path="/dynamic/:id" element={<DynamicView />} /> */}
        </Router>
    );
};

export default Root;

const links = () => (
    <div className="bg-white row p-1 gap-4 justify-center z-50 shadow-gray relative">
        <Link className="link bg-white px-3 p-1 font-bold mx-1 rounded shadow-1" to="/">
            Setup
        </Link>
        <Link className="link bg-white px-3 p-1 font-bold mx-1 rounded shadow-1" to="/sale">
            saleer
        </Link>
        {/* <Link className="link bg-white px-3 p-1 font-bold mx-1 rounded shadow-1" to="/result">
                    Result
                </Link> */}

        {/* {Bodies} */}
    </div>
);
