import React, { Fragment } from "react";
import PageStateProvider from "../PageStateKit/PageStateProvider";
import QueryContainer from "../Query/QueryContainer";
import HeaderContainer from "./HeaderContainer";

const OwlItems = ({ queries, queryContainerClass, Owl, queryChanged, header }) => {
    return (
        <Fragment>
            {queries && <QueryContainer className={queryContainerClass} Owl={Owl} queryChanged={queryChanged} queries={queries} />}
            <PageStateProvider Owl={Owl} />
            {header && <HeaderContainer Owl={Owl} HeaderBuilder={header} />}
        </Fragment>
    );
};
export default OwlItems;
