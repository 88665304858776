import React from "react";
import PageStateKit from "./PageStateKit";

const PageStateProvider = ({ Owl, pageStateKit = PageStateKit }) => {
    console.debug("PageStateProvider RD");
    [Owl.pageState, Owl.setPageState] = React.useState(["none"]);
    return React.useMemo(() => {
        const PageState = pageStateKit[Owl.pageState[0]];
        return <PageState owl={Owl} />;
    }, [Owl.pageState]);
};

export default PageStateProvider;
