import "./addons.css";
import React from "react";
import GetAddonsChild from "./AddonsProvider";

const AddButton = ({
    title,
    direction = "",
    buttonClass = "addon-button",
    placeholder = title.replace("+", ""),
    cancelTitle = "الغاء",
    type = "String",
    maximum = 1,
    values = [],
    containerClass,
    inputClass = "addon-input",
    children,
}) => {
    const container = React.useRef(null);

    function addType() {
        addChildAndCheckMaximum({ type, placeholder, cancelTitle, inputClass });
    }
    const mainClass = `addon-main ${direction} ${containerClass}`;
    return (
        <div className={mainClass} ref={container}>
            <div className="row-center justify-between">
                {children}
                <p onClick={addType} className={`${buttonClass}`}>
                    {title}
                </p>
            </div>
            <div className={`addon-container ${containerClass}`} style={{ display: "none" }} />
        </div>
    );

    function addChildAndCheckMaximum(prop) {
        container.current.lastChild.appendChild(GetChild(prop));
        if (container.current.lastChild.childNodes.length >= maximum) container.current.firstChild.firstChild.style.visibility = "hidden"; // { display: "none", marginTop: "-5px" };
    }

    function GetChild(prop) {
        values.push("");
        container.current.lastChild.style.display = "flex";
        prop.valueChanged = function (id, value) {
            values[id] = value;
        };
        prop.cancelClicked = function (id) {
            let secondChild = container.current.lastChild.childNodes[id + 1];
            if (secondChild) secondChild.id = id;
            values.splice(id, 1);
            if (container.current.lastChild.childNodes.length >= maximum) container.current.firstChild.firstChild.style = {};
            if (values.length < 1) {
                container.current.lastChild.style.display = "none";
            }
        };
        prop.id = container.current.lastChild.childElementCount;
        return GetAddonsChild(prop);
    }
};

export default AddButton;
