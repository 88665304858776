const StringOptions = ({ inputProps, valueChanged, cancel, id, saveTitle, options }) => {
    const container = document.createElement("div");
    container.className = "addon-input-container";
    container.id = id;

    const input = document.createElement("input");
    Object.entries(inputProps).forEach(([key, value]) => {
        console.log({ value });
        input[key] = value;
    });

    const onChanged = function () {
        if (input.value) valueChanged(parseInt(container.id), { value: input.value, option: select.value });
    };
    input.onkeydown = function (e) {
        if (e.key === "Enter") onChanged();
    };

    const btn = document.createElement("p");
    if (cancel) {
        btn.innerText = cancel.title;
        btn.className = "addon-cancel-button";
        btn.onclick = () => cancel.onClick(parseInt(container.id));
        input.onchange = onChanged;
    } else if (saveTitle) {
        btn.className = "addon-save-button";
        btn.innerText = saveTitle;
        btn.onclick = onChanged;
    }

    const select = document.createElement("select");
    select.className = "addon-select";

    options.forEach((option) => {
        const optionElement = document.createElement("option");
        optionElement.value = option.key;
        optionElement.innerText = option.title;
        select.appendChild(optionElement);
    });

    container.appendChild(select);
    container.appendChild(input);
    btn.innerText && container.appendChild(btn);

    setTimeout(() => {
        input.focus();
    }, 10);

    return container;
};

export default StringOptions;
