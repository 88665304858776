import React from "react";
import OwlSearchOptionsContainer from "../../SearchScroller/OwlSearchOptionsContainer";
import JsonToView from "../../Defaults/JsonToView";
import HorizontalScroller from "../../Utils/HorizontalScroller";
import { API } from "../SalePointAPI";
import Consts from "../Utils/Consts";
import Language from "../Utils/Language";
import AccountsNodeBuilder from "./AccountsNodeBuilder";

const createBillId = () => `bill-${Math.random().toString().split(".")[1]}`;
const getDefaultBills = () => {
    let billId = createBillId();
    const bill = {
        billId,
        bills: [billId],
    };
    localStorage.setItem("billsIds", JSON.stringify(bill));
    return bill;
};
const getStorageBills = () => JSON.parse(localStorage.getItem("billsIds")) || getDefaultBills();

const BillsAddButtons = ({ Owl }) => {
    [Owl.billsConatiner, Owl.setBillsContainer] = React.useState(getStorageBills());

    const ref = React.useRef(null);

    // React.useEffect(() => {
    //     if (Owl.billItems || !Owl.billsConatiner?.billId) return;
    //     setTimeout(() => {
    //         console.log(Owl.billItems);
    //         if (Owl.billItems) return;
    //         Owl.switchBills(Owl.billsConatiner.billId, false);
    //     }, 10);
    // }, []);

    React.useEffect(() => {
        localStorage.setItem("billsIds", JSON.stringify(Owl.billsConatiner));
    }, [Owl.billsConatiner]);

    const addNewBill = () => {
        let billId = createBillId();
        Owl.switchBills(billId);
        Owl.setBillsContainer((_bills) => {
            return {
                bills: [..._bills.bills, billId],
                billId: billId,
            };
        });

        setTimeout(() => {
            ref.current.scrollTo({
                top: 0,
                left: ref.current.lastChild.offsetLeft - ref.current.lastChild.offsetWidth * 2,
                behavior: "smooth",
            });
        }, 10);
    };

    const billChanged = (bill) => {
        Owl.switchBills(bill);
        Owl.setBillsContainer((_bills) => {
            return {
                bills: _bills.bills,
                billId: bill,
            };
        });
    };
    const remvoeBill = () => {
        let billIdToDelete = Owl.billsConatiner.billId;
        let bills = Owl.billsConatiner.bills;
        let index = bills.findIndex((o) => o === billIdToDelete);
        let billIdToView = "";

        localStorage.removeItem(billIdToDelete);
        bills.splice(index, 1);

        if (index === 0) {
            if (bills.length < 1) {
                billIdToView = createBillId();
                bills = [billIdToView];
            } else billIdToView = bills[index + 1];
        } else billIdToView = bills[index - 1];

        Owl.switchBills(billIdToView, false);
        Owl.setBillsContainer((_bills) => {
            return {
                //
                bills,
                billId: billIdToView,
            };
        });
    };

    function searchOptionsChange(item) {
        Owl.billItems.billFinancial1["account"] = item;
        Owl.syncBillsToStorage();
        console.log(Owl.billItems);
    }

    return (
        <>
            {/* <div className="fixed inset-0 overflow-auto z-50 bg-white">
                <JsonToView item={Owl} />
            </div> */}
            <div className="overflow-hidden relative row-center gap-1">
                <p className="absolute left-0 right-0" style={{ bottom: 4, height: 1, backgroundColor: "#f87171" }} />
                <div ref={ref} onWheel={HorizontalScroller} className="bills-add-buttons scroller row-center owl-text-xl gap-1">
                    {Owl.billsConatiner.bills.map((bill, i) =>
                        bill === Owl.billsConatiner.billId ? (
                            <p key={i} className="selected-bill transition-2">
                                {i + 1}
                            </p>
                        ) : (
                            <p key={i} className="bill-button transition-2 border-gray" onClick={() => billChanged(bill, i + 1)}>
                                {i + 1}
                            </p>
                        )
                    )}
                </div>
                <p className="add-bill-button" onClick={addNewBill}>
                    +
                </p>
            </div>
            <div className="wrap gap-2 justify-between">
                <OwlSearchOptionsContainer
                    api={API}
                    url="accounts"
                    queryContainerClass=""
                    queries={Consts.accountsQueries}
                    itemBuilder={AccountsNodeBuilder}
                    useCash={true}
                    gridClass=""
                    searchOptionsChange={searchOptionsChange}
                />
                <p className="bill-button self-center" style={{ fontSize: 13 }} onClick={remvoeBill}>
                    {Language.CencelTheList}
                </p>
            </div>
        </>
    );
};

export default BillsAddButtons;
