import React from "react";
import AddButton from "../../Addons/AddButton";
import SeparatedAddons from "../../Addons/SeparatedAddons";
import { API } from "../SalePointAPI";
import Language from "../Utils/Language";
import Button from "./Button";

const notes = [];
const discount = [];

const discontOptions = [
    { key: "Severed", title: "خصم مقطوعة" },
    { key: "Rate", title: "خصم نسبة" },
];

const defaultAccount = {
    accountTypeId: 0,
    address: "",
    distributor: 0,
    id: 999,
    name: "زبون نقدي",
    phone: "",
};

const BillDetailsFooter = ({ Owl }) => {
    const billConfirmerRef = React.useRef(null);
    const totalRef = React.useRef(null);
    const addonsRef = React.useRef(null);

    const sendBillRequest = (isSaleBill = false) => {
        const user = JSON.parse(localStorage.getItem("logged-user")) || {};
        console.log(Owl.billItems);

        let billFinancial1 = Owl.billItems.billFinancial1;
        const { account, note, total, currencyId, discount, discountType, paid } = billFinancial1;
        delete Owl.billItems.billFinancial1;

        let items = Object.values(Owl.billItems).reduce((acc, item) => {
            let _item = {
                currencyId: item.currencyId,
                discountNumber: item.discountNumber || 0,
                fillingId: item.fillingId || 0,
                id: item.id,
                note: item.note || "",
                number: item.billQuantity,
                priceType: "SalePrice",
                singlePrice: item.price1,
                storeId: item.storeId,
            };
            acc.push(_item);
            return acc;
        }, []);
        Owl.billItems.billFinancial1 = billFinancial1;

        const mock = {
            isSaleBill,
            boxId: user.boxId || 1066,
            furnishId: 0,
            providerId: 0,
            storeId: 0,
            tax: 0,
            userId: user.id || 2,
            account: account || defaultAccount,
            note: note || "",
            items,
            billFinancial1: {
                discount,
                currencyId,
                paid: account ? paid : total,
                discountType,
            },
        };

        console.log(mock);
        API.post("", mock)
            .then((response) => {
                console.log(response);
                alert("تم إرسال الفاتورة بنجاح");
            })
            .catch((error) => {
                Owl.setPageState(["error", error]);
                console.log(error);
            });
    };

    function updateTotalNode() {
        totalRef.current.firstChild.lastChild.lastChild.innerText = Owl.billItems.billFinancial1.total;
    }

    React.useEffect(() => {
        if (Owl.updateBillTotal) return;
        Owl.updateBillTotal = () => {};
        setTimeout(() => {
            if (Owl.billItems?.billFinancial1?.discount)
                discount.push({ key: Owl.billItems.billFinancial1.option, value: Owl.billItems.billFinancial1.discount });
            onDiscountChanged();
        }, 1);
    }, []);

    function onDiscountChanged() {
        const parent = document.createElement("fragment");
        getAmountNode(Owl.billItems.billFinancial1.total, "المجموع : ", parent);
        if (discount.length === 0) {
            Owl.billItems.billFinancial1.discount = 0;
            Owl.billItems.billFinancial1.discountType = "Severed";
            Owl.billItems.billFinancial1.net = 0;
            parent.firstChild.firstChild.style.minWidth = "unset";
            totalRef.current.replaceChildren(parent);
            Owl.updateBillTotal = updateTotalNode;
            return;
        }

        Owl.billItemChanged(discount[0], "UpdateDiscount");
        getAmountNode(Owl.billItems.billFinancial1.discount, "خصم : ", parent);
        getAmountNode(Owl.billItems.billFinancial1.net, "المجموع بعد الخصم : ", parent);
        totalRef.current.replaceChildren(parent);
        Owl.updateBillTotal = () => {
            Owl.billItems.billFinancial1.net = Owl.billItems.billFinancial1.total - Owl.billItems.billFinancial1.discount;
            parent.firstChild.lastChild.innerText = Owl.billItems.billFinancial1.total;
            parent.lastChild.lastChild.innerText = Owl.billItems.billFinancial1.net;
        };
    }

    return (
        <div
            onChange={(e) => {
                console.log({ e });
            }}
            ref={billConfirmerRef}
            className="p-2 gap-3 col "
            style={{ borderTop: " solid 1px #D1D5DB " }}>
            <AddButton title={Language.Note} values={notes} cancelTitle={Language.CencelTheNote} buttonClass="pointer px-1">
                <div ref={totalRef} />
            </AddButton>
            <div ref={addonsRef} />
            <div className="wrap gap-3 items-start">
                <Button extra="flex-grow text-center" onClick={() => sendBillRequest(true)} title={Language.PrintAndSave} />
                <SeparatedAddons
                    type="StringOptions"
                    addonsRef={addonsRef}
                    onChange={onDiscountChanged}
                    cancelTitle={Language.CencelTheDiscont}
                    buttonClass="flex-grow text-center this-button"
                    title={Language.AddDiscount}
                    options={discontOptions}
                    values={discount}
                    inputProps={{ type: "number" }}
                />
                <Button extra="flex-grow text-center" onClick={() => sendBillRequest(false)} title={Language.ReturnSales} />
            </div>
        </div>
    );
};

export default BillDetailsFooter;

const getAmountNode = (amount, title, parent) => {
    const pNode = document.createElement("p");
    pNode.style.display = "flex";

    const nodeTitle = document.createElement("span");
    nodeTitle.innerText = title;
    nodeTitle.style.minWidth = "120px";

    const nodeValue = document.createElement("span");
    nodeValue.innerText = amount;
    pNode.appendChild(nodeTitle);
    pNode.appendChild(nodeValue);
    parent.appendChild(pNode);
};
