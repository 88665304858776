var ev2 = new Event("input", { bubbles: true });
var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;

const CreateCounterNode = ({ item, onChange }) => {
    const node = document.createElement("div");
    node.className = "row-center";
    node.onclick = (e) => {
        e.target.id !== "itemQuantity" && onChange(e, item);
    };

    let plus = document.createElement("p");
    plus.innerText = "+";
    plus.id = "plus";
    node.append(plus);

    let quantity = document.createElement("input");
    quantity.defaultValue = item.billQuantity;
    quantity.id = "itemQuantity";
    quantity.type = "number";

    quantity.onfocus = ({ target }) => {
        target.value = "";
    };

    quantity.onchange = (e) => {
        console.log("onchange : ", e.target.value);
        let newQuantity = Math.round(parseFloat(e.target.value) * 100) / 100;
        if (item.quantity !== newQuantity) {
            item.newQuantity = newQuantity;
            onChange(e, item);
        }
    };
    quantity.onblur = (e) => {
        if (e.target.value === "") {
            e.target.value = item.billQuantity;
            return;
        }
        console.log("onchange : ", e.target.value);
        let newQuantity = Math.round(parseFloat(e.target.value) * 100) / 100;
        if (item.quantity !== newQuantity) {
            item.newQuantity = newQuantity;
            onChange(e, item);
        }
    };
    // nativeInputValueSetter.call(quantity, value);
    // quantity.dispatchEvent(ev2);

    node.append(quantity);

    let minus = document.createElement("p");
    minus.innerText = "-";
    minus.id = "minus";
    node.append(minus);

    return node;
};

export default CreateCounterNode;
