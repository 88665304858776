import React, { Fragment } from "react";

function removeAllChildNodes(parent) {
    while (parent.firstChild) parent.removeChild(parent.firstChild);
}

const SearchOptionsGridNodeContainer = ({ Owl, ItemBuilder, className, searchOptionsChange, defaultValue }) => {
    console.debug("Items Node Container RD");
    [Owl.showItems, Owl.setShowItems] = React.useState(false);
    Owl.itemsRef = React.useRef(null);
    React.useEffect(() => {
        if (Owl.setItems) return;
        Owl.setItems = (items, clear) => {
            if (clear) {
                items.splice(0, 0, defaultValue);
                console.log(items);
                Owl.items = [];
                removeAllChildNodes(Owl.itemsRef.current);
            }
            let i = Owl.items;
            items.map((item) => {
                Owl.itemsRef.current.appendChild(ItemBuilder({ item, i }));
                Owl.items.push(item);
                i++;
            });
        };
    }, []);

    React.useEffect(() => {
        if (Owl.showItems) {
            if (Owl.items.length < 1) Owl.search();
            Owl.items.map((item, i) => {
                Owl.itemsRef.current.appendChild(ItemBuilder({ item, i }));
            });
        }
    }, [Owl.showItems]);

    function onClick({ target }) {
        let item = Owl.items[parseInt(target.id)];
        if (item) {
            searchOptionsChange(item);
        }
    }

    return Owl.showItems ? (
        <div onScroll={Owl.onScroll} className={className}>
            <div ref={Owl.itemsRef} onClick={onClick} />
        </div>
    ) : (
        <Fragment />
    );
};

export default SearchOptionsGridNodeContainer;
