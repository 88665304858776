let wheeled = false;
const HorizontalScroller = (e) => {
    if (wheeled) return;
    wheeled = true;
    setTimeout(() => {
        wheeled = false;
    }, 200);

    let left = (e.currentTarget.clientWidth * e.deltaY) / 110;
    e.currentTarget.scrollBy({
        top: 0,
        left,
        behavior: "smooth",
    });
};

export default HorizontalScroller;
