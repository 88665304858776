import React from "react";
const getClass = (prop) => "owl-toggleed-bg" + (prop.value ? " owl-toggleed" : "");
const Boolean = ({ prop }) => {
    console.debug("Boolean RD");

    return (
        <div className={`owl-toggle-button ${prop.className}`} key={prop.key}>
            <p
                onClick={(e) => {
                    prop.value = !prop.value;
                    let target = e.currentTarget;
                    target.className = getClass(prop);
                    if (prop.storageKey) localStorage.setItem(prop.storageKey, prop.value);
                    if (prop.showInClearBar)
                        prop.clear = () => {
                            prop.value = !prop.value;
                            target.className = getClass({ value: false });
                        };
                    prop.queryChanged();
                }}
                className={getClass(prop)}>
                <span />
            </p>
            <p className="font-bold owl-text-xs">{prop.title}</p>
        </div>
    );
};

export default Boolean;
