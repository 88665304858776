import String from "./AddonsKit/String";
import BottomSheet from "./AddonsKit/BottomSheet";
import StringOptions from "./AddonsKit/StringOptions";

const AddonsKit = {
    String,
    BottomSheet,
    StringOptions,
};

const GetAddonsChild = (prop) => {
    return AddonsKit[prop.type](prop);
};

export default GetAddonsChild;
