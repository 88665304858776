import React from "react";
import GetQueryChild from "../QueryKit.js/QueryKit";

const QueryGroupContainer = ({ prop }) => {
    console.debug("QueryGroupContainer RD");

    return React.useMemo(() => {
        let _groups = [];
        prop.children?.map((group) => {
            let _bodies = [];
            group.children.map((child) => {
                child.queryChanged = () => {
                    prop.queryChanged(child);
                };
                if (child.children && !child.type) child.type = "Group";
                GetQueryChild(child, _bodies);
            });
            if (_bodies.length > 0)
                _groups.push({
                    className: group.className || "owl-query-section",
                    bodies: _bodies,
                });
        });
        return _groups.map((group) => (
            <div key={group.bodies[0].key} className={group.className}>
                {group.bodies}
            </div>
        ));
    }, []);
};
export default QueryGroupContainer;
