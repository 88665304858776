import React from "react";
import BillsAddButtons from "./BillsAddButtons";
import BillDetailsFooter from "./BillDetailsFooter";

const BillDetailsContainer = ({ Owl }) => {
    Owl.itemsDetailsRef = React.useRef(null);
    function touchToggle(e) {
        let newValue = e.target.value === "Touch" ? "Mouse" : "Touch";
        e.target.value = newValue;
        e.target.innerText = newValue;
        localStorage.setItem("TouchScreen", newValue);
    }

    return (
        <div className="details-inner-container">
            <BillsAddButtons Owl={Owl} />
            <div ref={Owl.itemsDetailsRef} className="col gap-3 overflow-auto scroller" />
            <BillDetailsFooter Owl={Owl} />
            <p
                onClick={() => {
                    window.location.reload();
                }}
                className="text-gray-800 text-left fixed bg-red-400 px-3 text-white rounded-lg  "
                style={{ left: 0, top: 0 }}>
                Reload
            </p>
            <p
                onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                }}
                className="text-gray-800 text-left fixed bg-red-400 px-3 text-white rounded-lg  "
                style={{ left: 80, top: 0 }}>
                clear
            </p>
            <p onClick={touchToggle} className="this-button fixed z-30 m-3 top-0 bg-red-400 right-0">
                {localStorage.getItem("TouchScreen") !== "Mouse" ? "Touch" : "Mouse"}
            </p>
        </div>
    );
};

export default BillDetailsContainer;
