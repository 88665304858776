import React from "react";
import String from "./String";
import Boolean from "./Boolean";
import Options from "./Options";
import Date from "./Date";
import Button from "./Button";
import ButtonsOptions from "./ButtonsOptions";
import Group from "./Group";

const QueryKit = {
    String,
    Boolean,
    Options,
    Date,
    Button,
    ButtonsOptions,
    Group,
};

const GetQueryChild = (child, bodies) => {
    const Body = QueryKit[child.type];
    if (Body) bodies.push(<Body key={child.key} prop={child} />);
};

export default GetQueryChild;
