import { hasValue } from "./Globals";

const QueryMethods = {
    Init: (queries, defaultQP) => {
        queries &&
            queries.length > 0 &&
            queries.map((group) => {
                QueryMethods.handleQueryValues(group, defaultQP);
            });
        return defaultQP;
    },

    handleQueryValues: (group, defaultQP) => {
        group?.children.map((query) => {
            if (query.storageKey) {
                let storageValue = localStorage.getItem(query.storageKey);
                if (storageValue) {
                    if (query.type === "Boolean") storageValue = storageValue === "true";
                    else query.value = storageValue;
                }
            }
            if (query.type === "Options") {
                query.className = query.options.find((o) => o.id == query.value)?.className || "";
            } else if (query.type === "ButtonsOptions") {
                // let i = query.options.findIndex((o) => o.id == query.value);
                // if (i !== -1) query.options[i].className = "owl-button-active";
            } else if (query.children) {
                QueryMethods.Init(query, defaultQP);
            }
            if (hasValue(query.value)) defaultQP[query.key] = { value: query.value, title: query.title || "_" };
        });
    },
};

export default QueryMethods;
