import { AddItemEvent, UpdateItemsNodes } from "../Items/ItemNode";
import { AddNewBillsItem, AddItemDetailsEvent } from "../ItemsDetails/ItemDetailsNode";

const ItemsSyncMethods = {
    SetupStorageMethods,
    AddItemEvent,
    AddItemDetailsEvent,
};
export default ItemsSyncMethods;

function SetupStorageMethods(Owl) {
    if (Owl.switchBills) return;
    addBillSwitchedEvent(Owl);

    storeTheBillToStorage(Owl);
    syncBillsToStorage(Owl);

    listnToBillItemChange(Owl);
}

function syncBillsToStorage(Owl) {
    Owl.syncBillsToStorage = function () {
        console.debug("syncBillsToStorage");
        localStorage.setItem(Owl.billsConatiner.billId, JSON.stringify(Owl.billItems));
    };
}

function storeTheBillToStorage(Owl) {
    Owl.storTheBills = function (storageRequesId = Math.random()) {
        Owl.storageRequesId = storageRequesId;
        setTimeout(() => {
            if (Owl.storageRequesId === storageRequesId) {
                Owl.syncBillsToStorage();
            }
        }, 1000);
    };
}

function addBillSwitchedEvent(Owl) {
    Owl.switchBills = function (billId, sync = true) {
        if (sync) {
            Owl.storageRequesId = Math.random();
            Owl.syncBillsToStorage();
        }
        Owl.billItems = JSON.parse(localStorage.getItem(billId)) || {
            billFinancial1: {
                account: null,
                total: 0,
                discount: 0,
                discountType: "Severed",
                note: "",
                paid: 0,
                currencyId: localStorage.getItem("currencyId") || 1,
            },
        };

        Owl.updateBillTotal();
        UpdateItemsNodes(Owl);
        AddNewBillsItem(Owl, true);
    };
}

function listnToBillItemChange(Owl) {
    Owl.billItemChanged = function (item, action) {
        ItemChangeActions[action](item, Owl);
        Owl.updateBillTotal();
        Owl.storTheBills();
    };
}

const ItemChangeActions = {
    AddQuantity,
    ReduceQuantity,
    DeleteBillItem,
    UpdateTotal,
    itemQuantity,
    None: () => {},
    UpdateDiscount,
};

function UpdateTotal(item, Owl) {
    Owl.billItems.billFinancial1.total += item.price1;
}

function UpdateDiscount(discount, Owl) {
    Owl.billItems.billFinancial1.discountType = discount.option;
    Owl.billItems.billFinancial1.discount = Math.round(parseFloat(discount.value) * 100) / 100;
    Owl.billItems.billFinancial1.net = Owl.billItems.billFinancial1.total - Owl.billItems.billFinancial1.discount;
}

function AddQuantity(item, Owl) {
    item.billQuantity++;
    Owl.billItems.billFinancial1.total += item.price1;
    Owl.billItems[item.id] = item;
    Owl.updateItem(item);
}

function itemQuantity(item, Owl) {
    let netQuantity = item.newQuantity - item.billQuantity;

    Owl.billItems.billFinancial1.total += netQuantity * item.price1;
    item.billQuantity = item.newQuantity;

    delete item.newQuantity;
    Owl.billItems[item.id] = item;
    Owl.updateItem(item);
}

function ReduceQuantity(item, Owl) {
    item.billQuantity--;
    Owl.billItems.billFinancial1.total -= item.price1;
    Owl.billItems[item.id] = item;
    Owl.updateItem(item);
}

function DeleteBillItem(item, Owl) {
    Owl.billItems.billFinancial1.total -= item.price1 * item.billQuantity;
    item.billQuantity = "";
    Owl.updateItem(item);
    Owl.itemsDetailsRef.current.childNodes.forEach((node, index) => {
        Owl.billItems[node.id].index = index;
    });
    Owl.itemsDetailsRef.current.removeChild(Owl.itemsDetailsRef.current.childNodes[item.index]);
    delete Owl.billItems[item.id];
}
