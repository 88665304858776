import React from "react";

function removeAllChildNodes(parent) {
    while (parent.firstChild) parent.removeChild(parent.firstChild);
}

const GridNodeContainer = ({ Owl, ItemBuilder, className }) => {
    console.debug("Items Node Container RD");
    Owl.itemsRef = React.useRef(null);
    React.useMemo(() => {
        Owl.setItems = (items, clear) => {
            if (clear) {
                Owl.items = {};
                removeAllChildNodes(Owl.itemsRef.current);
            }
            let i = Owl.itemsRef.current.childNodes.length;
            items.map((item) => {
                item.childIndex = i;
                i++;
                let billItem = Owl.billItems[item.id];
                if (billItem) item = billItem;
                Owl.items[item.id] = item;
                Owl.itemsRef.current.appendChild(ItemBuilder({ item, Owl }));
            });
        };
    }, []);

    return <div ref={Owl.itemsRef} className={className} />;
};

export default GridNodeContainer;
