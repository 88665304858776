import React from "react";
import GetAddonsChild from "./AddonsProvider";
let addonsId;
const SeparatedAddons = ({
    title,
    direction = "",
    buttonClass = "addon-button",
    cancelTitle = "الغاء",
    saveTitle = "إضافة",
    type = "String",
    maximum = 1,
    values = [],
    options,
    onChange,
    addonsRef,
    inputProps = {},
}) => {
    const addBtnRef = React.useRef(null);
    const _addonsRef = React.useMemo(() => addonsRef || React.createRef(null), []);

    React.useEffect(() => {
        if (addonsId) return;
        addonsId = type.slice(0, 1).toLowerCase() + type.slice(1);
        if (addonsRef) {
            addonsRef.current.id = addonsId;
            addonsRef.current.style.display = "none";
        }
        setDefaultPropsInput(inputProps, title);
        setTimeout(() => {
            console.log(values.length >= maximum);
            if (values.length >= maximum) addBtnRef.current.innerText = cancelTitle;
        }, 20);
    }, []);

    const mainClass = `addon-main ${direction}`;
    function addType() {
        _addonsRef.current.style.display = "block";
        _addonsRef.current.className = "addons-fade-in";
        addBtnRef.current.innerText = cancelTitle;
        let child = GetChild({ inputProps, type, saveTitle, options });
        _addonsRef.current.appendChild(child);
    }

    function cancelClicked(child) {
        if (child) {
            _addonsRef.current.className = "addons-fade-out";
            setTimeout(() => {
                child.remove();
            }, 200);
        }
        addBtnRef.current.innerText = title;
        values.splice(0, values.length);
        onChange && onChange(values);
    }

    function GetChild(prop) {
        values.push("");
        prop.id = _addonsRef.current.childElementCount;
        prop.valueChanged = function (id, value) {
            _addonsRef.current.className = "addons-fade-out";
            _addonsRef.current.lastChild.remove();

            values[id] = value;
            onChange && onChange(values);
        };
        return GetAddonsChild(prop);
    }

    return (
        <div className={mainClass}>
            <p
                ref={addBtnRef}
                onClick={() => {
                    if (values.length >= maximum) cancelClicked(_addonsRef.current.lastChild);
                    else addType();
                }}
                className={`${buttonClass}`}>
                {title}
            </p>
            {!addonsRef && <div ref={_addonsRef} id={addonsId} style={{ display: "none" }} />}
        </div>
    );
};

export default SeparatedAddons;

function setDefaultPropsInput(inputProps, title) {
    if (!inputProps.type) inputProps.type = "text";
    if (!inputProps.placeholder) inputProps.placeholder = title;
    if (!inputProps.className) inputProps.className = "addon-input";
}

// function addChildAndCheckMaximum(prop) {
//     if (_addonsRef.current.childNodes.length >= maximum) {
//         addBtnRef.current.innerText = cancelTitle;
//     }
// }
