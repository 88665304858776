import { hasValue } from "../Utils/Globals";

const UrlsGenerater = (query, url) => {
    // handelSort(query);
    // handleStorageQuery(query);

    // console.debug(query);
    return buildUrl(query, url);
};

export default UrlsGenerater;

const buildUrl = (query, url) => {
    query = Object.entries(query).reduce((acc, [key, value]) => {
        if (hasValue(value.value)) acc[key] = value.value;
        return acc;
    }, {});
    return `/${url}?${new URLSearchParams(query)}`;
};

const handelSort = (query) => {
    if (query.sort) {
        let desc = query.desc ? "-" : "";
        if (query.initalSort && query.sort !== query.initalSort) {
            query.sort = `${desc}${query.sort},${desc}${query.initalSort}`;
            delete query.initalSort;
        } else query.sort = `${desc}${query.sort}`;
        delete query.desc;
    }
};

const handleStorageQuery = (query) => {
    if (query.storageQuery && query.storageQuery.query) query = { ...query, ...query.storageQuery.query };
};
