const BottomSheet = ({ valueChanged, cancelClicked, id, saveTitle, placeholder = "النص", options, inputClass }) => {
    const container = document.createElement("div");
    container.className = "addon-input-container";
    container.id = id;

    const input = document.createElement("input");
    input.className = inputClass;
    input.placeholder = placeholder;

    input.type = input.onchange = function () {
        valueChanged(parseInt(container.id), { value: this.value, option: select.value });
    };
    input.onkeydown = function (e) {
        if (e.key === "Enter") this.blur();
    };

    const addBtn = document.createElement("p");
    addBtn.className = "addon-cancel-button";
    addBtn.innerText = saveTitle;

    addBtn.onclick = function () {
        // cancelClicked(parseInt(container.id));
        // container.remove();
    };

    const select = document.createElement("select");
    select.className = "addon-select";
    select.onchange = function () {
        valueChanged(parseInt(container.id), { value: input.value, option: this.value });
    };
    options.forEach((option) => {
        const optionElement = document.createElement("option");
        optionElement.value = option.key;
        optionElement.innerText = option.title;
        select.appendChild(optionElement);
    });

    container.appendChild(addBtn);
    container.appendChild(input);
    container.appendChild(select);

    setTimeout(() => {
        input.focus();
    }, 10);

    return container;
};

export default BottomSheet;
