import React from "react";
const SearchableOptions = ({ Owl, prop, type = "text", className }) => {
    Owl.searchRef = React.useRef(null);
    return (
        <input
            type={type}
            ref={Owl.searchRef}
            className={"owl-search-input " + className}
            defaultValue={prop.value}
            placeholder={prop.title}
            onChange={({ target }) => {
                console.log(target.value);
                return;
                checkChange(target, prop);
            }}
        />
    );
};
export default SearchableOptions;

const checkChange = (target, prop) => {
    let pastValue = target.value;
    setTimeout(() => {
        if (pastValue !== target.value) return;
        {
            prop.value = target.value;
            //if (prop.showInClearBar !== false)
            //     prop.clear = () => {
            //         target.value = "";
            //};
            prop.queryChanged(prop.value);
        }
    }, 700);
};
