import React from "react";
import GetQueryChild from "./QueryKit";

const Group = ({ prop }) => {
    const final = React.useMemo(() => {
        let _final = [];
        let _bodies = [];
        prop.children?.map((child) => {
            child.onChange = prop.queryChanged;
            if (child.children && !child.type) child.type = "Group";
            GetQueryChild(child, _bodies);
        });
        _final.push({
            className: prop.className || "owl-query-section",
            bodies: _bodies,
        });
        return _final;
    }, []);

    return final.map((group) => (
        <div key={group.bodies[0].key} className={group.className}>
            {group.bodies}
        </div>
    ));
};

export default Group;
