import CreateCounterNode from "./CreateCounterNode";

const CreateBillItemNode = (item, Owl) => {
    let billItemContainer = document.createElement("div");
    billItemContainer.className = "owl-item-details";
    billItemContainer.id = item.id;

    let nameAndPrice = document.createElement("p");
    let name = document.createElement("span");
    let price = document.createElement("span");
    let br = document.createElement("br");

    nameAndPrice.className = "flex-grow";
    name.innerText = item.name;
    price.innerText = item.price1;

    nameAndPrice.appendChild(name);
    nameAndPrice.appendChild(br);
    nameAndPrice.appendChild(price);
    billItemContainer.appendChild(nameAndPrice);

    const Methods = {
        plus: (item) => {
            Owl.billItemChanged(item, "AddQuantity");
        },
        minus: (item) => {
            if (item.billQuantity > 1) Owl.billItemChanged(item, "ReduceQuantity");
        },
        delete: (item) => {
            Owl.billItemChanged(item, "DeleteBillItem");
        },
        itemQuantity: (item) => {
            Owl.billItemChanged(item, "itemQuantity");
        },
    };

    let total = document.createElement("p");
    total.className = "total";
    total.innerText = item.billQuantity * item.price1;

    let deleteItem = document.createElement("img");
    deleteItem.src = "assets/delete.png";
    deleteItem.id = "delete";
    deleteItem.onclick = function () {
        Owl.billItemChanged(item, "DeleteBillItem");
    };

    const onChange = (e, item) => {
        const calc = Methods[e.target.id];
        if (!calc) return;
        calc(item);
        if (e.target.id !== "itemQuantity") e.currentTarget.childNodes[1].value = item.billQuantity;
        total.innerText = item.billQuantity * item.price1;
    };

    billItemContainer.appendChild(CreateCounterNode({ item, onChange }));
    billItemContainer.appendChild(total);
    billItemContainer.appendChild(deleteItem);

    Owl.itemsDetailsRef.current.appendChild(billItemContainer);
    Owl.itemsDetailsRef.current.scrollTop = Owl.itemsDetailsRef.current.scrollHeight;
};

export default CreateBillItemNode;

export const updateNodeChildren = (node, item) => {
    let itemQuantity = node.childNodes[1].childNodes[1];
    itemQuantity.value = item.billQuantity;

    node.childNodes[2].innerText = item.billQuantity * item.price1;
    node.scrollIntoView({ block: "nearest" });
};
