const currecnies = [
    { title: "1", id: 1 },
    { title: "2", id: 2 },
];
const limits = [
    { title: "25", id: 25 },
    { title: "50", id: 50 },
];
const cols = [
    //
    { key: "id", initialWidthFactor: 0.6, title: "الرقم" },
    { key: "price", initialWidthFactor: 0.4, title: "السعر" },
];

const Consts = {
    spliter: {
        cols: [0.6, 0.4],
        className: "split-container",
        storageKey: "split-colums",
    },
    queries: [
        {
            className: "scroller owl-query-section px-4",
            children: [
                { key: "currencyId", type: "None", title: "العملة", value: localStorage.getItem("currencyId") || 1, options: currecnies },
                { key: "limit", type: "None", title: "", value: 50, options: limits },
                {
                    key: "query",
                    type: "String",
                    title: "البحث عن ...",
                    value: "",
                    containerClass: "flex-grow col relative",
                    showInClearBar: false,
                },
                JSON.parse(
                    localStorage.getItem("inventory-list") ||
                        JSON.stringify({ key: "storeId", type: "Options", value: -1, options: [{ title: "كل المتاجر", id: -1 }] })
                ),
                JSON.parse(
                    localStorage.getItem("groups-list") ||
                        JSON.stringify({ key: "groupId", type: "ButtonsOptions", value: -1, options: [{ title: "الكل", id: -1 }] })
                ),
            ],
        },
    ],
    accountsQueries: [
        {
            className: "",
            children: [
                { key: "UserId", type: "None", title: "user id", value: localStorage.getItem("userId") || 2 },
                { key: "limit", type: "None", title: "", value: 25, options: limits },
                { key: "offset", type: "None", title: "", value: 0, options: limits },
                {
                    key: "query",
                    type: "String",
                    title: "اسم الزبون",
                    // value: "زبون نقدي",
                    default: "999",
                    containerClass: "flex-grow col relative",
                    showInClearBar: false,
                },
                // JSON.parse(
                //     localStorage.getItem("inventory-list") ||
                //         JSON.stringify({ key: "storeId", type: "Options", value: -1, options: [{ title: "كل المتاجر", id: -1 }] })
                // ),
                // JSON.parse(
                //     localStorage.getItem("groups-list") ||
                //         JSON.stringify({ key: "groupId", type: "ButtonsOptions", value: -1, options: [{ title: "الكل", id: -1 }] })
                // ),
            ],
        },
    ],
};

export default Consts;
